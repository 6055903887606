<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import { debounce, pickBy } from "lodash";
import { useRouter } from "vue-router";
import { useTrailStore } from "../trailStore";

const router = useRouter();

const trailStore = useTrailStore();

const query = ref("");

const filters = computed(() => {
	return trailStore.filters;
});

watch(
	filters,
	(newValue, oldValue) => {
		trailStore.isSearching = true;
		router
			.push({ name: "home", query: pickBy(newValue) })
			.then(async () => {
				return await new Promise((resolve) => {
					setTimeout(resolve, 1000);
				});
			})
			.then(() => {
				trailStore.isSearching = false;
			});
	},
	{ deep: true }
);

const updateQuery = debounce(() => {
	trailStore.filters.q = query.value;
}, 500);

onMounted(() => {
	if (Object.keys(router.currentRoute.value.query).length) {
		trailStore.filters = {
			q: router.currentRoute.value.query.q ?? "",
			r: router.currentRoute.value.query.r ?? "",
			a: router.currentRoute.value.query.a ?? "",
		};

		query.value = router.currentRoute.value.query.q ?? "";
	}
});
</script>

<template>
	<form @submit.prevent="submitForm">
		<div class="trail-finder__field">
			<label for="search">Search by Trail Name</label>
			<input
				type="text"
				name="search"
				id="search"
				v-model="query"
				@input="updateQuery"
			/>
		</div>
		<div class="trail-finder__field">
			<label for="region">Region</label>
			<select name="region" id="region" v-model="trailStore.filters.r">
				<option value=""></option>
				<option
					v-for="region in trailStore.regions"
					:value="region.slug"
					v-text="region.name"
				></option>
			</select>
		</div>
		<div class="trail-finder__field">
			<label for="activity">Activity</label>
			<select name="activity" id="activity" v-model="trailStore.filters.a">
				<option value=""></option>
				<option
					v-for="activity in trailStore.activities"
					:value="activity.slug"
					v-text="activity.name"
				></option>
			</select>
		</div>
	</form>
</template>
