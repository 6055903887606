import { createApp } from "vue";
import { createPinia } from "pinia";
import { createRouter, createWebHashHistory } from "vue-router";
import "./style.css";
import App from "./App.vue";
import Home from "./pages/Home.vue";
import Trail from "./pages/Trail.vue";

if (document.getElementById("tooele-trails-map")) {
	const pinia = createPinia();

	const routes = [
		{
			path: "/",
			name: "home",
			component: Home,
			props: (route) => ({
				q: route.query.q,
				r: route.query.r,
				a: route.query.a,
			}),
		},
		{
			path: "/trail/:slug",
			name: "trail",
			component: Trail,
			props: (route) => ({
				slug: route.params.slug,
			}),
		},
	];

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
	});

	const app = createApp(App);

	app.use(router);
	app.use(pinia);

	app.mount("#tooele-trails-map");
}
