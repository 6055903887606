<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { pickBy } from "lodash";
import { useRouter } from "vue-router";
import { useTrailStore } from "../trailStore";

const router = useRouter();

const trailStore = useTrailStore();

const props = defineProps({
	slug: String,
});

const trail = ref(null);

onMounted(() => {
	trail.value = trailStore.getTrailBySlug(props.slug) ?? null;
});

onUnmounted(() => {
	trail.value = null;
});

function goBackHome() {
	router.push({ name: "home", query: pickBy(trailStore.filters) });
}
</script>

<template>
	<div class="trail-page">
		<div class="trail-page__back">
			<a href="#" @click.prevent="goBackHome" class="trail-page__back-button">
				Go back
			</a>
		</div>

		<div v-if="trail" class="trail-page__content">
			<div class="trail-page__header">
				<h2 v-text="trail.name" class="trail-page__title"></h2>
				<h3 v-text="trail.region.name" class="trail-page__region"></h3>
			</div>

			<div class="trail-page__icons">
				<div v-for="activity in trail.activities" class="trail-page__icon-container">
					<img :src="activity.icon" alt="" class="trail-page__icon" />
				</div>
			</div>

			<div class="trail-page__type" v-html="trail.typeHtml"></div>

			<div class="trail-page__images">
				<div v-for="image in trail.images" class="trail-page__image-container">
					<img :src="image" alt="" class="trail-page__image" />
				</div>
			</div>

			<div class="trail-page__desc" v-html="trail.descHtml"></div>
		</div>
	</div>
</template>
