<script setup>
import { useTrailStore } from "../trailStore";

const trailStore = useTrailStore();
</script>

<template>
	<div>
		<template v-if="!trailStore.isSearching">
			<div v-if="trailStore.filteredTrails.length" class="trail-finder__trails">
				<div
					v-for="trail in trailStore.filteredTrails"
					class="trail-finder__trail"
				>
					<h4 v-text="trail.name" class="trail-finder__trail-title"></h4>

					<p v-text="trail.region.name" class="trail-finder__trail-region"></p>

					<div class="trail-finder__trail-icons">
						<div
							v-for="activity in trail.activities"
							class="trail-finder__trail-icon-container"
						>
							<img
								:src="activity.icon"
								alt=""
								class="trail-finder__trail-icon"
							/>
						</div>
					</div>

					<div class="trail-finder__trail-link">
						<router-link :to="{ name: 'trail', params: { slug: trail.slug } }"
							>Learn More <span></span
						></router-link>
					</div>
				</div>
			</div>

			<div v-else class="trail-finder__no-results">
				<h4>No trails found!</h4>
			</div>
		</template>

		<div v-else class="trail-finder__searching">
			<p>Searching...</p>
		</div>
	</div>
</template>
